
import map from 'lodash/map';
import { mapGetters, mapState } from 'vuex';
import Store from '@/models/Store';
import ManagementMixin from '@/mixins/ManagementMixin';
import { handleErrorUI } from '@/util/error';
import CommonMixin from '@/mixins/CommonMixin';
import PaginationMixin from '@/mixins/PaginationMixin';
import Filters from '@/components/Common/Filters.vue';
import StoreTable from '@/components/Management/Store/Table.vue';
import { mockStores } from '@/util/mocks';
import FilterStoreDrawer from '@/components/Management/Store/FilterDrawer.vue';
import store from '@/store';
import { checkPermission } from '@/api/utils';

interface StoreViewModel extends Store {
  action: string;
}

const mapStores = (stores: Store[]): StoreViewModel[] =>
  map(stores, store => ({ ...store, action: '' }));

export default {
  components: { FilterStoreDrawer, StoreTable, Filters },
  mixins: [ManagementMixin, CommonMixin, PaginationMixin],
  data() {
    return {
      drawerShown: false,
      data: [],
      selectedStore: null,
      deleteConfirmationShown: false,
      loading: false
    };
  },
  computed: {
    ...mapGetters('tenant', ['hasResetAccount'])
  },
  async created() {
    await this.fetchStores();
  },
  methods: {
    viewAllowed(name) {
      return checkPermission(name);
    },
    showDeleteConfirmation(store: Store) {
      this.selectedStore = store;
      this.deleteConfirmationShown = true;
    },
    openDrawer() {
      this.drawerShown = true;
    },
    closeDrawer() {
      this.drawerShown = false;
    },
    async fetchStores() {
      this.loading = true;
      // if (this.hasResetAccount) {
      try {
        const { data, pagination } = await this.$repo.store.getStores(
          this.pagination
        );
        this.data = mapStores(data);
        this.data = this.data.filter(store => {
          return store.status !== 'DISABLE';
        });
        this.updatePagination(pagination);
      } catch (err) {
        handleErrorUI(err);
      } finally {
        this.loading = false;
      }
      // } else {
      //   this.data = mockStores(10);
      //   this.loading = false;
      // }
    },
    async onDelete() {
      await this.$repo.store.updateStore(this.selectedStore.id, {
        ...this.selectedStore,
        status: 'DISABLE'
      });
      this.deleteConfirmationShown = false;
      this.$q.notify({
        message: this.$t('SettingsManagement.successfully_deleted'),
        type: 'positive',
        position: 'top',
        timeout: 3000
      });
      await this.fetchStores();
    },
    async paginationChangeHandler() {
      await this.fetchStores();
    }
  }
};
