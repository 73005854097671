var render = function render(){var _vm=this,_c=_vm._self._c;return _c('m-page',{attrs:{"title":_vm.$t('StoreManagement.StoreManagement')}},[_c('div',{staticClass:"row q-py-md q-col-gutter-md items-stretch"},[_c('div',{staticClass:"col-md-11 col-sm-10"},[_c('q-card',{staticClass:"full-height"},[_c('q-card-section',{staticClass:"q-py-sm"},[_c('Filters',{attrs:{"stores":[]}},[_c('div',{staticClass:"other-filters"})])],1)],1)],1),(_vm.viewAllowed('FlowStoreManagementCreate'))?_c('div',{staticClass:"col-md-1 col-sm-2"},[_c('router-link',{attrs:{"to":{ name: 'management:store:add' }}},[_c('m-card-action',{attrs:{"icon":"fas fa-plus","label":_vm.$t('SettingsManagement.add'),"bg-color":"secondary","color":"white"}})],1)],1):_vm._e()]),_c('q-card',[_c('q-card-section',[_c('StoreTable',{attrs:{"data":_vm.data,"loading":_vm.loading,"pagination":_vm.quasarPagination,"no-action":!_vm.hasResetAccount},on:{"request":function($event){return _vm.onPaginationChange($event.pagination)}},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(_vm.viewAllowed('FlowStoreManagementView'))?_c('router-link',{attrs:{"to":{
              name: 'management:store:view',
              params: { id: props.row.id }
            }}},[_c('c-btn',{attrs:{"text-color":"secondary","label":_vm.$t('SettingsManagement.view')}})],1):_vm._e(),(_vm.viewAllowed('FlowStoreManagementEdit'))?_c('router-link',{attrs:{"to":{
              name: 'management:store:edit',
              params: { id: props.row.id }
            }}},[_c('c-btn',{attrs:{"text-color":"secondary","label":_vm.$t('SettingsManagement.edit')}})],1):_vm._e(),(_vm.viewAllowed('FlowStoreManagementDelete'))?_c('c-btn',{attrs:{"id":"row-delete","text-color":"negative","label":_vm.$t('SettingsManagement.delete')},on:{"click":function($event){return _vm.showDeleteConfirmation(props.row)}}}):_vm._e()]}}])})],1)],1),_c('m-drawer',{model:{value:(_vm.drawerShown),callback:function ($$v) {_vm.drawerShown=$$v},expression:"drawerShown"}},[_c('FilterStoreDrawer',{attrs:{"value":_vm.filters},on:{"close":_vm.closeDrawer,"input":_vm.onFilterChange}})],1),_c('confirm-delete-dialog',{attrs:{"name":(_vm.selectedStore || {}).name,"show":_vm.deleteConfirmationShown,"on-delete":_vm.onDelete},on:{"close":function($event){_vm.deleteConfirmationShown = false}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }